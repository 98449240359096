import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useCookies } from "react-cookie";
import debounce from "lodash.debounce";

import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { ImageGrid, LoginPrompt } from "./";

// lil important client side piece of info here
const hmm = "v8XUNkUsWE7h9k28cQQuwvW1UwhkgTHPWaGM6FYt";

const Wrap = styled.div`
  max-width: 60rem;
  height: 100%;
  padding: 0;
  position: relative;
  margin: auto;
`;
const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  // hmm
  /* position: fixed; */
  left: 0;
  top: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.4rem;
  background-color: #2c2c2ce6;
`;
const Name = styled.h1`
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: #01cdfe;
  font-size: 1.5rem;
  margin: 0rem;
`;
const SubName = styled.p`
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-weight: bold;
  font-size: 0.9rem;
  color: #ff71ce;
`;
const SubSubName = styled.p`
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.6rem;
  color: #b967ff;
`;
const SubSubSubName = styled.p`
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 0.65rem;
  font-weight: bold;
  color: #05ffa1;
`;

const ImageBodyWrap = styled.div`
  // approx height of top header
  /* margin-top: 7rem; */
`;

const TitleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
`;
const TitleCol = styled.div`
  padding-right: 10px;
`;

const ButtonBox = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ButtonWrapper = styled.div`
  margin: auto;
`;

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
    padding: theme.spacing(0),
  },
}));

export const Main = () => {
  const [images, setImages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [version, setVersion] = useState("v");
  const [releaseDate, setReleaseDate] = useState("1970-01-01T00:00:00.000Z");
  const [pageError, setPageError] = useState("");
  const [imageBeingViewed, setImageBeingViewed] = useState("");
  const [savedY, setSavedY] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // like useState but with delicious cookies instead
  const [cookies, setCookie] = useCookies(["trent"]);

  const setImageAndScroll = (image) => {
    console.log("saving scroll", window.scrollY);
    setSavedY(window.scrollY);
    setImageBeingViewed(image);
  };

  // if there's a weird value in that cookie that can't be decoded
  let password = "";
  try {
    password = atob(cookies.trent);
  } catch (error) {}

  const setPassword = (passToSave) => {
    setCookie("trent", btoa(passToSave), { path: "/" });
  };

  useEffect(() => {
    // really just for initial fetch
    if (images.length === 0 && password && !pageError && !isLoading) {
      getImages();
    }
  });

  const getImages = () => {
    setIsLoading(true);
    console.log("~~~~~> fetching initial images ~~~~~>");
    const key = btoa(`${password}${hmm}`);
    fetch(`/api/images?key=${encodeURIComponent(key)}`)
      .then((res) => res.json())
      .then((data) => {
        setImages(data.featuredList);
        setTotalCount(data.fetchedLength);
        setVersion(data.release.version);
        setReleaseDate(data.release.createdAt);
      })
      .catch((err) => {
        setPageError("Something is wrong with your credentials, comrade");
        setPassword("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleScrollToBottom = () => {
    if (password === "" || imageBeingViewed !== "") {
      return; // skip if not logged in. or user viewing an image
    }
    setIsLoading(true);
    console.log("~~~~~> fetching MOAR images ~~~~~>");
    const key = btoa(`${password}${hmm}`);
    fetch(`/api/images?key=${encodeURIComponent(key)}`)
      .then((res) => res.json())
      .then((data) => {
        // TODO: logic to remove duplicates??
        setImages([...images, ...data.featuredList]);
        setTotalCount(data.fetchedLength);
        setVersion(data.release.version);
        setReleaseDate(data.release.createdAt);
      })
      .catch((err) => {
        setPageError("Something is wrong with your credentials, comrade");
        setPassword("");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePasswordSubmit = (password) => {
    setPageError("");
    setPassword(password);
  };

  const handleLogout = (event) => {
    setPageError("");
    setPassword("");
    setImages([]);
    event.preventDefault();
  };

  // Binds our scroll event handler
  window.onscroll = debounce(() => {
    // Checks that the page has scrolled to the bottom
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      handleScrollToBottom();
    }
  }, 1000);

  // material UI styling
  const classes = useStyles();

  const loginPrompt = (
    <LoginPrompt
      errorToDisplay={pageError}
      onSubmitPassword={handlePasswordSubmit}
    />
  );

  const mainView = (
    <Wrap>
      <HeaderWrap>
        <TitleBox>
          <TitleCol>
            <Name>Library of NIИ</Name>
            <SubName>
              <strong>Welcome to the Library of NIИ</strong>
            </SubName>
          </TitleCol>
          <TitleCol>
            <SubSubName>Serving {totalCount} images</SubSubName>
            <SubSubName>Library last updated 5/19/2018</SubSubName>
            <SubSubName>
              {version} deployed {new Date(releaseDate).toLocaleString()}
            </SubSubName>
            <SubSubSubName>
              You've scrolled through {images.length} memes
            </SubSubSubName>
          </TitleCol>
        </TitleBox>
        <ButtonBox>
          <ButtonWrapper>
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Fab
              disabled={!imageBeingViewed}
              size="large"
              color="secondary"
              aria-label="back"
              className={classes.button}
              onClick={() => {
                // window.scrollTo(0, 0);
                // setImages([]);
                setImageBeingViewed("");
                console.log("scrolling to", savedY);
                window.scrollTo(0, savedY);
                setTimeout(() => window.scrollTo(0, savedY), 500);
              }}
            >
              <ArrowBackIcon />
            </Fab>
          </ButtonWrapper>
        </ButtonBox>
      </HeaderWrap>
      <ImageBodyWrap>
        <ImageGrid
          imageBeingViewed={imageBeingViewed}
          setImageBeingViewed={setImageAndScroll}
          imageList={images}
          isLoading={isLoading}
        />
      </ImageBodyWrap>
      {imageBeingViewed == "" && !isLoading && (
        <div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleScrollToBottom}
          >
            Load more
          </Button>
        </div>
      )}
    </Wrap>
  );

  return password === "" ? loginPrompt : mainView;
};

Main.defaultProps = {};

Main.propTypes = {};

export default Main;
