import React from "react";
import styled from "styled-components/macro";
import { BarLoader } from "react-spinners";

const ImageWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
`;
const ImageBox = styled.div`
  height: 5.8rem;
  width: 5.8rem;
  align-items: center;
  alight-self: center;
  text-align: center;
  padding: 0.1rem;
`;
// so img doesn't take over the page:
const Image = styled.img`
  background-color: #270030;
  height: 5.8rem;
  width: 5.8rem;
`;

const Loading = styled.div`
  text-align: center;
  padding-top: 5rem;
  margin-left: auto;
  margin-right: auto;
`;

const LoadingBottomBox = styled.div`
  height: 1rem;
  text-align: center;
  padding: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 15em;
`;

const Helper = styled.p`
  margin: auto;
  padding: 0.5rem;
`;

const BigImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const ImageGrid = ({
  isLoading,
  imageList,
  imageBeingViewed,
  setImageBeingViewed,
}) => {
  const handleImageOnClick = (e, url) => {
    setImageBeingViewed(url);
    e.preventDefault();
  };

  const content =
    imageList.length === 0 ? (
      <Loading>
        <BarLoader
          height={2}
          heightUnit={"em"}
          width={20}
          widthUnit={"em"}
          color={"#4A90E2"}
        />
      </Loading>
    ) : imageBeingViewed ? (
      // idk if this div is necessary
      <div>
        <BigImage src={imageBeingViewed} />
      </div>
    ) : (
      <>
        {imageList.map((item, index) => (
          // using index as key not ideal, but dup images may be shown..
          <ImageBox key={index}>
            {/* TODO: eventually use onClick, open image within app */}
            <a
              href={item.imageUrl}
              onClick={(e) => handleImageOnClick(e, item.imageUrl)}
            >
              <Image src={item.imageUrl} />
            </a>
          </ImageBox>
        ))}
        <LoadingBottomBox>
          {isLoading ? (
            <BarLoader
              height={1}
              heightUnit={"em"}
              width={15}
              widthUnit={"em"}
              color={"#4A90E2"}
            />
          ) : null}
        </LoadingBottomBox>
        {/* <Helper>Scroll down to load more memes</Helper> */}
      </>
    );

  return <ImageWrap>{content}</ImageWrap>;
};

ImageGrid.defaultProps = {};

ImageGrid.propTypes = {};

export default ImageGrid;
