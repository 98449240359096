import React, { useState } from "react";
import styled from "styled-components/macro";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { green } from "@material-ui/core/colors";

const PromptWrap = styled.div`
  max-width: 40rem;
  margin: auto;
  text-align: center;
`;
const PromptImage = styled.img`
  max-width: 100%;
`;
const ErrorTitle = styled.h2`
  color: red;
  font-size: 2rem;
  margin-top: 0;
`;

const VerticalForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const useStyles = makeStyles(theme => ({
  textField: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  }
}))(Button);

export const LoginPrompt = ({ errorToDisplay, onSubmitPassword }) => {
  const [passwordField, setPasswordField] = useState("");

  const handleChange = event => {
    setPasswordField(event.target.value);
  };
  const handleSubmit = event => {
    onSubmitPassword(passwordField);
    setPasswordField("");
    event.preventDefault();
  };

  const classes = useStyles();

  return (
    <PromptWrap>
      <PromptImage src="disclaimer.png" />
      <VerticalForm onSubmit={handleSubmit}>
        <TextField
          id="outlined-password-input"
          label="Password"
          className={classes.textField}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          value={passwordField}
          onChange={handleChange}
        />
        <ColorButton
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          size="large"
        >
          Submit
        </ColorButton>
      </VerticalForm>
      <ErrorTitle>{errorToDisplay}</ErrorTitle>
    </PromptWrap>
  );
};

LoginPrompt.defaultProps = {};

LoginPrompt.propTypes = {};

export default LoginPrompt;
