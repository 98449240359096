import React from "react";
import styled from "styled-components/macro";
import { CookiesProvider } from "react-cookie";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Main } from "./components";

const AppWrapper = styled.div`
  align-items: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
});

function App() {
  return (
    <CookiesProvider>
      <AppWrapper>
        <ThemeProvider theme={theme}>
          <Main />
        </ThemeProvider>
      </AppWrapper>
    </CookiesProvider>
  );
}

export default App;
